/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import PayoutApprovalSearchResults from './PayoutApprovalSearchResults';
import * as PASearchConstants from '../PayoutApproval/PayoutApprovalSearchConstants';
import SearchResults from './SearchResults.json';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import {
  fcnSearch,
  resetAccounPayable,
  updatePayoutApprovalActionFCN,
  setFPASearchData
} from '../../../AccountPayables/Store/Actions/AccountPayableActions';
import {
  generateUUID,
  getUTCTimeStamp
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import { getFinancialPayoutDetails } from '../../../FinancialPayOut/Store/Actions/financialPayout/financialPayoutActions';
import Swal from 'sweetalert2';
import { Prompt } from 'react-router-dom';
import { removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';

function FCNInquiry (props) {
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetAccounPayable());
  const onUpdateApproval = value =>
    dispatch(updatePayoutApprovalActionFCN(value));
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const logInUserIDConst = useSelector(state => state.sharedState.logInUserID);
  const { searchQuery } = props;
  const [showTable, setShowTable] = React.useState(false);
  const [apiCall, setApiCall] = React.useState(false || searchQuery?.tabIndex >= 0);
  const [{ showFCNError }, setShowError] = React.useState(false);
  const { value } = props;
  React.useEffect(() => {
    setShowError(false);
  }, [value]);
  let errorMessagesArray = [];
  let successMessagesArray = [];

  const [redirect, setRedirect] = React.useState(0);

  const [showNoRecords, setShowNoRecords] = React.useState(false);
  const [selectedDataForDelete, setSelectedDataForDelete] = React.useState([]);
  const [selectedDataForApproval, setSelectedDataForApproval] = React.useState(
    []
  );
  const [approvalArray, setApprovalArray] = React.useState([]);
  const [deleteArray, setDeleteArray] = React.useState([]);
  let payloadViewData = [];
  const [tableData, setTableData] = React.useState([]);
  const [payeeIdTypeCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [tableDataDesc, setTableDataDesc] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const onSearch = values => dispatch(fcnSearch(values));
  payloadViewData = useSelector(
    state => state.accountPayableState.fcnSearchData
  );
  const approvalUpdateFCN = useSelector(
    state => state.accountPayableState.updateApprovalDataFCN
  );
  const onPayoutDetails = searchValues =>
    dispatch(getFinancialPayoutDetails(searchValues));
  const payoutDetails = useSelector(
    state => state.financialPayoutState.singlePayoutDetails
  );

  const payeeTypeDD = props.addDropdowns && props.addDropdowns['G1#G_CMN_ENTY_TY_CD'] ? props.addDropdowns['G1#G_CMN_ENTY_TY_CD'] : [];
  const reasonDD = props.addDropdowns && props.addDropdowns['F1#F_RSN_CD'] ? props.addDropdowns['F1#F_RSN_CD'] : [];

  useEffect(() => {
    SearchResults.searchObj.map(row => {
      row.uid1 = generateUUID();
      row.uid2 = generateUUID();
    });
  }, [SearchResults]);

  useEffect(() => {
    if(searchQuery?.tabIndex >= 0){
      if(searchQuery.fcnQuery){
        setValues(searchQuery.fcnQuery);
      }                  
      if(payloadViewData?.length){
        setShowTable(true);        
      }      
    }else{
      resetFCN();
    }
  }, []);

  useEffect(() => {
    if(!(searchQuery?.tabIndex >= 0)){
      resetFCN();
    }
  }, [props.value]);

  const [values, setValues] = React.useState({
    fcn: ''
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const selectedArrayApproval = data => {
    setSelectedDataForApproval(data);
  };
  const selectedArrayDelete = data => {
    setSelectedDataForDelete(data);
  };

  // Map function to get the Description for corresponding Code
  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };

  useEffect(() => {
    if (payloadViewData && payloadViewData.systemFailure) {
      setShowTable(false);
      errorMessagesArray.push(PASearchConstants.SYS_ERROR);
      props.showError(errorMessagesArray);
      setTableData([]);
    } else if (payloadViewData && payloadViewData.respcode === '01' && apiCall) {
      setAllowNavigation(false);

      setShowTable(true);
      errorMessagesArray = [];
      props.showError(errorMessagesArray);
      setTableData(payloadViewData.enterpriseSearchresultVO?.searchResults);
    } else if (payloadViewData?.length === 0 || payloadViewData?.errorMessages?.length) {
      setShowTable(false);

      if (
        payloadViewData && payloadViewData.length === 0
      ) {
        errorMessagesArray.push(PASearchConstants.NO_RECODRS_FOUND);
      } else if (
        payloadViewData &&
        payloadViewData.errorMessages &&
        payloadViewData.errorMessages.length > 0
      ) {
        payloadViewData.errorMessages.map((option, index) => {
          errorMessagesArray.push(option);
        });
      }
      props.showError(errorMessagesArray);
    }
  }, [payloadViewData]);

  useEffect(() => {
    const tempPayeePayload = [];
    tableData.map((row, index) => {
      const outputData = row;
      tempPayeePayload.push({
        ...outputData,
        uid1: generateUUID(),
        uid2: generateUUID(),
        payeeTypeDesc: mapCodeDescription(row.payeeType, payeeTypeDD),
        reasonCodeDesc: mapCodeDescription(row.reasonCode, reasonDD)
      });
    });

    setTableDataDesc(tempPayeePayload);
  }, [tableData]);

  const editLink = row => {
    setAllowNavigation(false);
    onPayoutDetails({
      fcn: row.fcn,
      searchBy: 'searchByFCN',
      lob: [null],
      fcnSearch: 1,
      payeeSearch: 0,
      payeeType: null,
      payeeID: null,
      payeeIDTypeCode: null
    });
  };
  
  useEffect(() => {
    if (payoutDetails && !payoutDetails.systemFailure && isSearch) {
      setAllowNavigation(false);
      const payoutUpdate = true;
      props.history.push({
        pathname: '/FinancialPayoutUpdate',
        state: { payoutUpdate, addSuccessData: payoutDetails.enterpriseSearchresultVO?.searchResults[0] },
        pageName: 'Payoutapproval'
      });
    }
    if (payoutDetails && payoutDetails.systemFailure) {
      props.showError([PASearchConstants.SYS_ERROR]);
    }
  }, [payoutDetails]);

  useEffect(() => {
    if (tableDataDesc.length > 0) {
      const approvalArraySeperation = tableDataDesc.map(
        x => x.approvalIndicator
      );
      setApprovalArray(approvalArraySeperation);
      const deleteArraySeperation = tableDataDesc.map(x => {
        if (x.approvalIndicator) {
          x.deleteIndicator = false;
        }
      });
      setDeleteArray(deleteArraySeperation);
      tableDataDesc.map((value) => {
        value.checkApproveFlag = logInUserIDConst === value.userID ? true : logInUserIDConst === value.addedAuditUserID;
      });
    }
  }, [tableDataDesc]);

  const resetFCN = () => {
    setAllowNavigation(false);
    setValues({ fcn: '' });
    setShowTable(false);
    errorMessagesArray = [];
    setShowError(false);
    props.showError(errorMessagesArray);
    props.showSuccess([]);
    onReset();
    setShowNoRecords(false);
    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  const searchFCN = () => {
    props.showError([]);
    props.showSuccess([]);
    var showFCNError = false;
    if (values.fcn === '') {
      showFCNError = true;
      setShowError({ showFCNError: showFCNError });
      errorMessagesArray = [];
      errorMessagesArray.push(PASearchConstants.FCN_REQUIRED);
      props.showError(errorMessagesArray);
    } else {
      showFCNError = false;
      setShowError(false);
      errorMessagesArray = [];
      props.showError(errorMessagesArray);

      const value = {
        fcn: values.fcn,
        lineOfBusiness: null,
        fcnSearch: 1,
        payeeSearch: 0,
        payeeType: null,
        payeeID: null,
        payeeIDTypeCode: null,
        searchBy: 'searchByFCN'
      };
      setShowTable(false);
      setApiCall(true);
	  setIsSearch(true);
      onSearch(removeLeadingTrailingSpaces(value));
      dispatch(setFPASearchData({fcnQuery : values, tabIndex: props.value, fromPath: window.location.pathname}));
    }
  };
  const onSubmitConfirm = () => {
    const submitApprovalData = [];
    const approvalData = {
      approvalCode: false,
      deleteCode: false,
      fcn: ''
    };
    for (let i = 0; i < approvalArray.length; i++) {
      if (selectedDataForApproval.includes(tableDataDesc[i].uid1)) {
        approvalArray[i] = true;
        tableDataDesc[i].approvalIndicator = true;
        approvalData.auditUserID = logInUserIDConst;
        approvalData.auditTimeStamp = getUTCTimeStamp();
        approvalData.fcn = tableDataDesc[i].fcn;
        approvalData.approvalCode = tableDataDesc[i].approvalIndicator;
        approvalData.deleteCode = tableDataDesc[i].deleteIndicator ? tableDataDesc[i].deleteIndicator : false;
        submitApprovalData.push(approvalData);
      }
      if (approvalArray[i]) {
        tableDataDesc[i].approvalIndicator = true;
        tableDataDesc[i].deleteIndicator = approvalData.deleteCode ? approvalData.deleteCode : false;
      }
    }
    for (let i = 0; i < deleteArray.length; i++) {
      if (selectedDataForDelete.includes(tableDataDesc[i].uid2)) {
        deleteArray[i] = true;
        tableDataDesc[i].deleteIndicator = true;
        approvalData.auditUserID = logInUserIDConst;
        approvalData.auditTimeStamp = getUTCTimeStamp();
        approvalData.fcn = tableDataDesc[i].fcn;
        approvalData.deleteCode = tableDataDesc[i].deleteIndicator;
        approvalData.approvalCode = tableDataDesc[i].approvalIndicator ? tableDataDesc[i].approvalIndicator : false;
        submitApprovalData.push(approvalData);
      }
      if (deleteArray[i]) {
        tableDataDesc[i].approvalIndicator = approvalData.approvalCode ? approvalData.approvalCode : false;
        tableDataDesc[i].deleteIndicator = true;
      }
    }

    onUpdateApproval(submitApprovalData);
    setApprovalArray(approvalArray);
    setDeleteArray(deleteArray);
    setTableDataDesc([...tableDataDesc]);
  };
  const submitForApproval = () => {
    props.showError([]);
    props.showSuccess([]);
    if (selectedDataForDelete.length > 0) {
      Swal.fire({
        customClass: {
          confirmButton: 'btn-text1 btn-transparent mr-3',
          cancelButton: 'btn1 btn-success',
          container: 'unsaved-changes-popup2'
        },
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        icon: 'warning',
        html:
          '<h4>Are you sure you want to delete?</h4 >',
        showCancelButton: true,
        confirmButtonText: 'Cancel!',
        cancelButtonText: 'Continue! <i class="fa fa-arrow-right ml-1"></i>'
      }).then(result => {
        if (result.value) {
        } else {
          onSubmitConfirm();
        }
      });
    } else {
      onSubmitConfirm();
    }
  };
  useEffect(() => {
    if (approvalUpdateFCN) {
      successMessagesArray = [];
      errorMessagesArray = [];
      if (!approvalUpdateFCN.systemFailure && approvalUpdateFCN.errorMessages && approvalUpdateFCN.errorMessages.length > 0) {
        approvalUpdateFCN.errorMessages.map((data, index) => {
          if (data.indexOf(PASearchConstants.SYS_ERROR) > -1) {
            errorMessagesArray.push(data);
          }
        });
      }
      if (!approvalUpdateFCN.systemFailure && approvalUpdateFCN.respcode === '01') {
        if (selectedDataForDelete.length > 0) {
          setShowTable(false);
          setTableDataDesc([]);
        }
        if (approvalUpdateFCN.errorMessages && approvalUpdateFCN.errorMessages.length > 0) {
          successMessagesArray = approvalUpdateFCN.errorMessages;
    const searchFCN = {
      fcn: values.fcn,
      lineOfBusiness: null,
      fcnSearch: 1,
      payeeSearch: 0,
      payeeType: null,
      payeeID: null,
      payeeIDTypeCode: null,
      searchBy: 'searchByFCN'
    };
          onSearch(removeLeadingTrailingSpaces(searchFCN));
        } else {
          successMessagesArray.push(PASearchConstants.SYSTEM_SUCCESSFULL);
        }
      }
      if (!approvalUpdateFCN.systemFailure && approvalUpdateFCN.respcode === '02') {
        errorMessagesArray = approvalUpdateFCN.errorMessages;
      }
      if (approvalUpdateFCN.systemFailure) {
        errorMessagesArray.push(PASearchConstants.SYS_ERROR);
      }
      props.showSuccess(successMessagesArray);
      props.showError(errorMessagesArray);
    }
  }, [approvalUpdateFCN]);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
        when={allowNavigation}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
      <div className="mui-custom-form input-md">
        <TextField
          id="fcn"
          required
          label={PASearchConstants.FCN_TEXT}
          InputLabelProps={{
            shrink: true
          }}
          value={values.fcn}
          onChange={handleChange('fcn')}
          inputProps={{ maxLength: 14 }}
          helperText={showFCNError ? PASearchConstants.FCN_REQUIRED : null}
          error={showFCNError ? PASearchConstants.FCN_REQUIRED : null}
        />
      </div>

      <div className="float-right mb-2 mr-3">
        <Button className="btn-tab-pannel" onClick={() => searchFCN()}>
          <i class="fa fa-search" aria-hidden="true"></i>
          Search
        </Button>

        <Button
          variant="outlined"
          color="primary"
          className="bt-tabreset btn-transparent ml-1"
          onClick={() => resetFCN()}
        >
          <i class="fa fa-undo" aria-hidden="true"></i>
          Reset
        </Button>
      </div>

      <div className="clearfix"></div>

      <div className="mt-3 table-no-wrap">
        {showTable ? (
          <div className="tab-holder my-3 p-0">
            <div className="table-heading">
              <h2 className="mb-3">Payout Approval or Deletion</h2>
            </div>
            <PayoutApprovalSearchResults
              approvalArray={approvalArray}
              deleteArray={deleteArray}
              setAllowNavigation={setAllowNavigation}
              selectedArrayRowsApproval={selectedArrayApproval}
              selectedArrayRowsDelete={selectedArrayDelete}
              tableData={tableDataDesc}
              editLink={editLink}
            />
            <div className="float-right">
              <Button
                className="btn btn-primary ml-1 mb-2"
                onClick={() => submitForApproval()}
              >
                Submit
              </Button>
            </div>
            <div className="clearfix"></div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(FCNInquiry);
