/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './accountReceivableActionTypes';
import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler/index';
const headers = {
  'Access-Control-Allow-Origin': '*'
};
export const resetFCNSearchAccountReceivable = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};
export const resetPayload = () => {
	return {
	  type: actionTypes.RESETDATA,
	  payload: null
	};
  };

export const SearchPayeeIDNameAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIALENTITY_GETPAYEEIDNAME}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setSearchPayeeIDNameDetails(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSearchPayeeIDNameDetails(sendingResponse));
      });
  };
};

export const setSearchPayeeIDNameDetails = (searchResult) => {
  return {
    type: actionTypes.FINANCIALENTITY_PAYEE_SEARCH_DATA,
    payeeDetails: searchResult
  };
};

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const getSubDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, value)
      .then(response => {
        dispatch(setSubDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setSubDropdownData(error));
      });
  };
};

export const setSubDropdownData = (subdropdownData) => {
  return {
    type: actionTypes.SUB_DROPDOWNDATA,
    subdropdownData: subdropdownData
  };
};

export const getReasonCodeMapDataAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.REASON_CODE_DROPDOWNS}?activityTypeCode=${value}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setReasonCodeMapDataAction(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setReasonCodeMapDataAction(sendingResponse));
      });
  };
};

export const setReasonCodeMapDataAction = (reasonCodeMapdropdownData) => {
  return {
    type: actionTypes.REASON_CODE_MAP,
    reasonCodeMapdropdownData: reasonCodeMapdropdownData
  };
};

export const searchFCNAccountReceivableAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.FCN_SEARCH_ENDPOINT}?receivableFCN=${value}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse.length === 0) {
          dispatch(setFCNAccountReceivable(sendingResponse));
        } else {
          dispatch(setFCNAccountReceivable(sendingResponse.enterpriseBaseVO));
dispatch(resetARLinksData());
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setFCNAccountReceivable(sendingResponse));
      });
  };
};

export const setFCNAccountReceivable = (fcns) => {
  return {
    type: actionTypes.SEARCH_ACCOUNT_RECEIVABLE,
    fcns: fcns
  };
};

export const resetPayeeSearchAccountReceivable = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const resetARSingleRecordData = () => {
    return {
      type: actionTypes.RESET_EDIT_AR_SINGLE_RECORD,
      data: null
    };
};

export const searchPayeeAccountReceivableAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYEE_SEARCH_ENDPOINT}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setPayeeAccountReceivable([], value));
        } else {
          dispatch(setPayeeAccountReceivable(sendingResponse, value));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error, true);
        dispatch(setPayeeAccountReceivable(sendingResponse, value));
      });
  };
};

export const setPayeeAccountReceivable = (payees, values) => {
  return {
    type: actionTypes.PAYEE_SEARCH_ACCOUNT_RECEIVABLE,
    payees: payees,
    searchValues: values
  };
};

export const getPayeeIdType = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYEE_TYPE_ID_DROPDOWN_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (response) {

        }
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      });
  };
};

export const setPayeeIdTypeDropdown = (data) => {
  return {
    type: actionTypes.PAYEE_ID_TYPE_DROPDOWN,
    payeeIdTypeDropdown: data
  };
};

export const searchMCNAccountReceivableAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYEE_SEARCH_ENDPOINT}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setMCNAccountReceivable([], value));
        } else {
          dispatch(setMCNAccountReceivable(sendingResponse, value));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error, true);
        dispatch(setMCNAccountReceivable(sendingResponse, value));
      });
  };
};

export const setMCNAccountReceivable = (mcns, values) => {
  return {
    type: actionTypes.MCN_SEARCH_ACCOUNT_RECEIVABLE,
    mcns: mcns,
    searchValues: values
  };
};
export const validatePayeeDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALIDATE_PAYEE_DETAILS}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeDetails(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeDetails(sendingResponse));
      });
  };
};
export const setPayeeDetails = (data) => {
  return {
    type: actionTypes.PAYOUT_DETAILS,
    payoutDetails: data
  };
};

export const goToNextPageAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.GO_TO_NEXT_PAGE_END}`, value)// custompY
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setGotoNextPageData([]));
        } else {
          dispatch(setGotoNextPageData(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setGotoNextPageData(sendingResponse));
      });
  };
};
export const searchClaimsReceivableAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.FCN_SEARCH_ENDPOINT}?receivableFCN=${value}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setClaimsReceivable([]));
        } else {
          dispatch(setClaimsReceivable(sendingResponse.enterpriseBaseVO));
dispatch(resetARLinksData());
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setClaimsReceivable(sendingResponse));
      });
  };
};

export const setClaimsReceivable = (claims) => {
  return {
    type: actionTypes.SEARCH_CLAIMS_RECEIVABLE,
    claims: claims
  };
};

export const setGotoNextPageData = (nextPageData) => {
  return {
    type: actionTypes.GOTONEXTPAGE,
    nextPageData: nextPageData
  };
};

export const resetARLinksData = (claims) => {
return {
type: actionTypes.RESET_AR_LINK_DATA
};
  };
export const addGrossLevelAdd = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.ADD_GROSS_LEVEL_ADD}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setGrossLevelAdd([]));
        } else {
          dispatch(setGrossLevelAdd(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setGrossLevelAdd(sendingResponse));
      });
  };
};

export const editGrossLevel = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.GROSS_LEVEL_EDIT_ENDPOINT}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setGrossLevelEdit([]));
        } else {
          dispatch(setGrossLevelEdit(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setGrossLevelEdit(sendingResponse));
      });
  };
};

export const setGrossLevelEdit = (GrossLevelEditData) => {
  return {
    type: actionTypes.GROSSLEVEL_EDIT,
    GrossLevelEditData: GrossLevelEditData
  };
};

export const agingDaysList = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.AR_AGING_DAYS}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (response) {

        }
        dispatch(setAgingDays(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setAgingDays(sendingResponse));
      });
  };
};

export const setGrossLevelAdd = (addData) => {
  return {
    type: actionTypes.GROSS_LEVEL_ADD,
    addData: addData
  };
};
export const ARTransferEligibility = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ARTRANSFER_VIEW}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setARTransferEligible([]));
        } else {
          dispatch(setARTransferEligible(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setARTransferEligible(sendingResponse));
      });
  };
};
export const setARTransferEligible = (data) => {
  return {
    type: actionTypes.ARTRANSFER_ELIGIBLE,
    arTransferEligibleDetails: data
  };
};

export const getWriteOffDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FETCH_WRITE_OFF_DETAILS}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setWriteOffDetails([]));
        } else {
          dispatch(setWriteOffDetails(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setWriteOffDetails(sendingResponse));
      });
  };
};
export const getWriteOffDetailsReset = () => {
  return dispatch => {
    dispatch(setWriteOffDetails([]));
  };
};
export const setWriteOffDetails = (writeOffData) => {
  return {
    type: actionTypes.FETCH_WRITE_OFF,
    writeOffData: writeOffData
  };
};

export const getWriteOffRestablishmentDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FETCH_WRITE_OFF_RESTABLISHMENT_DETAILS}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setWriteOffRestablishmentDetails([]));
        } else {
          dispatch(setWriteOffRestablishmentDetails(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setWriteOffRestablishmentDetails(sendingResponse));
      });
  };
};
export const getWriteOffRestablishmentDetailsReset = () => {
  return dispatch => {
    dispatch(setWriteOffRestablishmentDetails([]));
  };
};
export const setWriteOffRestablishmentDetails = (writeOffRestablishmentData) => {
  return {
    type: actionTypes.FETCH_WRITE_OFF_RESTABLISHMENT,
    writeOffRestablishmentData: writeOffRestablishmentData
  };
};

export const ARReverseEligibility = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ARREVERSE_VIEW}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setARReverseEligible([]));
        } else {
          dispatch(setARReverseEligible(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setARReverseEligible(sendingResponse));
      });
  };
};
export const setARReverseEligible = (data) => {
  return {
    type: actionTypes.ARREVERSE_ELIGIBLE,
    arReverseEligibleDetails: data
  };
};
export const saveARReverse = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.ARREVERSE_SAVE}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setARReverseResult([]));
        } else {
          dispatch(setARReverseResult(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setARReverseResult(sendingResponse));
      });
  };
};
export const setARReverseResult = (data) => {
  return {
    type: actionTypes.SAVE_REVERSE,
    arReverseResults: data
  };
};

export const ARAdjustEligibility = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ARADJUST_VIEW}`, value, { headers: headers })
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setARAdjustEligible([]));
        } else {
          dispatch(setARAdjustEligible(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setARAdjustEligible(sendingResponse));
      });
  };
};
export const setARAdjustEligible = (data) => {
  return {
    type: actionTypes.ARADJUST_ELIGIBLE,
    arAdjustEligibleDetails: data
  };
};
export const addLienLevyAdd = (value, file) => {
  const formInfo = new FormData();

  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.ADD_GROSS_LEVEL_ADD}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setLienLevyAdd(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setLienLevyAdd(sendingResponse));
      });
  };
};
export const lienLevyUpdate = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.LIEN_LEVY_HOLDER_UPDATE}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setLienLevyUpdate(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setLienLevyUpdate(sendingResponse));
      });
  };
};

export const saveClaimsReceivable = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_CLAIMS_RECEIVABLE}`, formInfo
      , formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setSaveClaimsReceivable([]));
        } else {
          dispatch(setSaveClaimsReceivable(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSaveClaimsReceivable(sendingResponse));
      });
  };
};

export const setLienLevyAdd = (data) => {
  return {
    type: actionTypes.LIEN_LEVY_ADD,
    addLienLevyData: data
  };
};

export const setSaveClaimsReceivable = (saveClaims) => {
  return {
    type: actionTypes.CLAIMS_RECEIVABLE_SAVE,
    saveClaims: saveClaims
  };
};

export const lienLevyHolderIdTypeDropDownAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYEE_TYPE_ID_DROPDOWN_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse.payeeIdTypeCode) {
          dispatch(setLienLevyHolderIdTypeDropDown(sendingResponse.payeeIdTypeCode));
        } else {
          dispatch(setLienLevyHolderIdTypeDropDown([]));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setLienLevyHolderIdTypeDropDown(sendingResponse));
      });
  };
};
export const setLienLevyHolderIdTypeDropDown = (data) => {
  return {
    type: actionTypes.LIEN_LEVY_HOLDER_TYPE_ID_DROPDOWN,
    lienLevyHolderIdType: data
  };
};

export const setLienLevyUpdate = (data) => {
  return {
    type: actionTypes.LIEN_LEVY_UPDATE,
    lienLevyUpdateData: data
  };
};

export const setAgingDays = (agingDaysList) => {
  return {
    type: actionTypes.AR_AGING_DAYS,
    agingDaysList: agingDaysList
  };
};

export const advancePaymentUpdate = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    });
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.GROSS_LEVEL_EDIT_ENDPOINT}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setAdvancePaymentUpdate(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setAdvancePaymentUpdate(sendingResponse));
      });
  };
};

export const setAdvancePaymentUpdate = (data) => {
  return {
    type: actionTypes.ADVANCE_PAYMENT_UPDATE,
    advancePaymentResponse: data
  };
};

export const getDefaultRecoupmentDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.GET_DEFAULT_RECOUPMENT_DETAILS}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setRecoupmentDetails([]));
        } else {
          dispatch(setRecoupmentDetails(sendingResponse));
        }
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setRecoupmentDetails(sendingResponse));
      });
  };
};

export const setRecoupmentDetails = (data) => {
  return {
    type: actionTypes.FETCH_RECOUPMENT_DETAILS,
    fetchRecoupmetDetails: data
  };
};

export const validateLienHolderDetails = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALIDATE_LIEN_HOLDER_NAME_DETAILS}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setHolderDetails(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setHolderDetails(sendingResponse));
      });
  };
};

export const setHolderDetails = (data) => {
  return {
    type: actionTypes.VALIDATE_LIEN_HOLDER_DETAILS,
    lienHolderDetails: data
  };
};

export const bulkViewTransfer = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BULKVIEWTRANSFER}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setBulkViewTransfer(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setBulkViewErrorTransfer(sendingResponse));
      });
  };
};

export const saveBulkTransferAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVEBULKTRANSFER}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setSaveBulkTransfer(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSaveBulkTransfer(sendingResponse));
      });
  };
};

export const setSaveBulkTransfer = (value) => {
  return {
    type: actionTypes.SAVE_BULK_TRANSFER,
    saveBulkTransfer: value
  };
};

export const setBulkViewErrorTransfer = (value) => {
  return {
    type: actionTypes.BULK_VIEW_TRANSFER_ERROR,
    bulkViewErrorTransfer: value
  };
};
export const setBulkViewTransfer = (value) => {
  return {
    type: actionTypes.BULK_VIEW_TRANSFER,
    bulkViewTransfer: value
  };
};

export const bulkViewReverse = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BULKVIEWREVERSE}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setBulkViewReverse(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setBulkViewReverseError(sendingResponse));
      });
  };
};

export const saveBulkReverse = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVEBULKVIEWREVERS}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setBulkViewSaveReverse(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setBulkViewSaveReverse(sendingResponse));
      });
  };
};

export const bulkViewWriteOff = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BULK_VIEW_WRITEOFF}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setBulkViewWriteOff(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setBulkViewWriteOffError(sendingResponse));
      });
  };
};

export const saveBulkViewWriteOff = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_BULK_VIEW_WRITEOFF}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setSaveBulkViewWriteOff(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSaveBulkViewWriteOff(sendingResponse));
      });
  };
};

export const bulkViewChangePayeeStatus = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BULK_VIEW_CHANGE_PAYEE_STATUS}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setChangePayeeStatus(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setChangePayeeStatusError(sendingResponse));
      });
  };
};

export const saveChangePayeeStatus = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BULK_SAVE_CHANGE_PAYEE_STATUS}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setSavePayeeStatus(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSavePayeeStatus(sendingResponse));
      });
  };
};

export const bulkViewWriteOffReest = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BULK_VIEW_WRITEOFF_REEST}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setBulkViewWriteOffReest(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setBulkViewWriteOffReestError(sendingResponse));
      });
  };
};

export const saveBulkViewWriteOffReest = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_BULK_WRITEOFF_REEST}`, value)
      .then(response => {
        const sendingResponse = commonAPIHandler(response);
        dispatch(setSaveBulkViewWriteOff(sendingResponse));
      })
      .catch(error => {
        const sendingResponse = commonAPIHandler(error);
        dispatch(setSaveBulkViewWriteOff(sendingResponse));
      });
  };
};

export const setBulkViewWriteOff = (value) => {
  return {
    type: actionTypes.BULK_VIEW_WRITEOFF,
    viewBulkWriteOff: value
  };
};

export const setBulkViewWriteOffError = (value) => {
  return {
    type: actionTypes.BULK_VIEW_WRITEOFF_ERROR,
    viewBulkWriteOffError: value
  };
};

export const setSaveBulkViewWriteOff = (value) => {
  return {
    type: actionTypes.SAVE_BULK_VIEW_WRITE_OFF,
    saveBulkViewWriteOffResult: value
  };
};

export const setChangePayeeStatus = (value) => {
  return {
    type: actionTypes.BULK_VIEW_PAYEE_STATUS,
    changePayeeStatus: value
  };
};

export const setChangePayeeStatusError = (value) => {
  return {
    type: actionTypes.BULK_VIEW_PAYEE_STATUS_ERROR,
    changePayeeStatusError: value
  };
};

export const setSavePayeeStatus = (value) => {
  return {
    type: actionTypes.SAVE_BULK_VIEW_PAYEE_STATUS,
    savePayeeStatusResult: value
  };
};

export const setBulkViewWriteOffReest = (value) => {
  return {
    type: actionTypes.BULK_VIEW_WRITE_OFF_REEST,
    viewBulkWriteOffReest: value
  };
};

export const setBulkViewWriteOffReestError = (value) => {
  return {
    type: actionTypes.BULK_VIEW_WRITE_OFF_REEST_ERROR,
    viewBulkWriteOffReestError: value
  };
};

export const setSaveBulkViewWriteOffReest = (value) => {
  return {
    type: actionTypes.SAVE_BULK_WRITE_OFF_REEST,
    saveBulkViewWriteOffReestResult: value
  };
};

export const setBulkViewSaveReverse = (value) => {
  return {
    type: actionTypes.SAVE_BULK_REVERSE,
    saveBulkViewReverse: value
  };
};

export const setBulkViewReverse = (value) => {
  return {
    type: actionTypes.BULK_VIEW_REVERSE,
    bulkViewReverse: value
  };
};

export const setBulkViewReverseError = (value) => {
  return {
    type: actionTypes.BULK_VIEW_REVERSE_ERROR,
    bulkViewReverseError: value
  };
};
